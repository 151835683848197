<template>
    <div class="flex flex-col min-h-screen">
        <header-section />
        <hero-section class="mt-20 flex-grow" />
        <footer-section />
    </div>
</template>

<script setup>
    import headerSection from "@/components/dashboard/header.vue"
    import heroSection from "@/components/dashboard/hero.vue"
    import footerSection from "@/components/footer.vue"
</script>

<script>
    export default {
        components: {headerSection,heroSection,footerSection},
        mounted() {
            window.scrollTo(0, 0);
        }
    }
</script>