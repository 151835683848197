<template>
  <div class="flex flex-col items-center sm:justify-center bg-gray-100 text-center p-8 md:p-0 mb-16">
    <div class="container mx-auto space-y-8 sm:space-y-12 sm:py-16">
        <h2 class="text-2xl sm:text-3xl font-bold -mb-2 sm:mb-0">How to get started?</h2>

        <div class="flex flex-col space-y-3 sm:space-y-5 text-left pb-20 mx-auto max-w-screen-md">
            <div class="flex space-x-3 shadow-lg bg-white rounded-xl p-4">
                <div class="flex-shrink-0">
                    <div class="rounded-full h-12 w-12 flex items-center justify-center bg-purple-200 text-purple-700 font-bold text-lg">1</div>
                </div>
                <div class="hidden sm:flex flex-shrink-0 items-center justify-center w-20">
                    <i class="fas fa-link fa-2xl sm:fa-3x text-purple-700"></i>
                </div>
                <div class="flex-grow">
                    <h3 class="font-bold text-lg sm:text-xl">Link your Onlyfans account</h3>
                    <p>Connect your OnlyFans account to get started.</p>
                </div>
            </div>
            <div class="flex space-x-3 shadow-lg bg-white rounded-xl p-4">
                <div class="flex-shrink-0">
                    <div class="rounded-full h-12 w-12 flex items-center justify-center bg-purple-200 text-purple-700 font-bold text-lg">2</div>
                </div>
                <div class="hidden sm:flex flex-shrink-0 items-center justify-center w-20">
                    <i class="fas fa-check-circle fa-2xl sm:fa-3x text-purple-700"></i>
                </div>
                <div class="flex-grow">
                    <h3 class="font-bold text-lg sm:text-xl">Get verified</h3>
                    <p>Verify your account for security purposes.</p>
                </div>
            </div>
            <div class="flex space-x-3 shadow-lg bg-white rounded-xl p-4">
                <div class="flex-shrink-0">
                    <div class="rounded-full h-12 w-12 flex items-center justify-center bg-purple-200 text-purple-700 font-bold text-lg">3</div>
                </div>
                <div class="hidden sm:flex flex-shrink-0 items-center justify-center w-20">
                    <i class="fas fa-cloud-upload-alt fa-2xl sm:fa-3x text-purple-700"></i>
                </div>
                <div class="flex-grow">
                    <h3 class="font-bold text-lg sm:text-xl">Upload content for posting</h3>
                    <p>Upload your media files ready for posting.</p>
                </div>
            </div>
            <div class="flex space-x-3 shadow-lg bg-white rounded-xl p-4">
                <div class="flex-shrink-0">
                    <div class="rounded-full h-12 w-12 flex items-center justify-center bg-purple-200 text-purple-700 font-bold text-lg">4</div>
                </div>
                <div class="hidden sm:flex flex-shrink-0 items-center justify-center w-20">
                    <i class="fas fa-rocket fa-2xl sm:fa-3x text-purple-700"></i>
                </div>
                <div class="flex-grow">
                    <h3 class="font-bold text-lg sm:text-xl">Launch Your Popularity</h3>
                    <p>Gain massive recognition from your posts and skyrocket your popularity.</p>
                </div>
            </div>

        </div>

        <router-link to="/dashboard/accounts" class="px-8 py-4 bg-purple-700 text-white rounded hover:bg-purple-600">Get Started</router-link>
        </div>
    </div>
</template>

<script>
export default {}
</script>
