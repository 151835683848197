<template>
    <div>
        <div class="w-full mx-auto mt-4">
            <lr-file-uploader-regular
                css-src="https://unpkg.com/@uploadcare/blocks@0.17.1/web/file-uploader-inline.min.css"
                class="my-settings lr-wgt-common cursor-pointer h-60"></lr-file-uploader-regular>
        </div>
    </div>
</template>

<script>
import { connectBlocksFrom } from "@uploadcare/blocks/abstract/connectBlocksFrom";

const STYLES = "https://unpkg.com/@uploadcare/blocks@0.17.1/web/file-uploader-inline.min.css";

connectBlocksFrom("https://unpkg.com/@uploadcare/blocks@0.17.1/web/blocks-browser.min.js");

export default {
    data() {
        return {
            uploadedFiles: [],
        };
    },
    methods: {
        handleUpload(event) {
            this.uploadedFiles = event.detail.data.map(file => ({
                naeTme: file.name,
                cdnUrl: file.cdnUrl,
                size: file.size,
                isStored: file.isStored,
                mimype: file.mimeType,
            }));
            console.log("uploaded images being emited", this.uploadedFiles);
            this.$emit('emitFilesUploaded', this.uploadedFiles);
            // Process the uploaded files and send their URLs to your API
        }
    },
    mounted() {
        window.addEventListener('LR_DATA_OUTPUT', this.handleUpload);
    },
    beforeDestroy() {
        window.removeEventListener('LR_DATA_OUTPUT', this.handleUpload);
    }
};
</script>

<style scoped>
    .my-settings {
        --cfg-pubkey: "219f4459786be7386ab4";
        --cfg-store: 1;
        --cfg-img-only:1;
    }
</style>