<template>
    <section class="relative bg-cover bg-center bg-no-repeat" style="height: 100vh;" :style="bgImage">
        <div class="absolute inset-0 bg-purple-700 opacity-50"></div>
        <div class="relative z-10 flex flex-col items-center justify-center h-full px-6 text-center text-white">
        <h1 class="text-4xl md:text-5xl lg:text-6xl font-bold">Empower your Onlyfans Performance with PostVERSE</h1>
        <p class="mt-4 text-lg md:text-xl lg:text-2xl">Automated Share-for-Share Marketing Platform for Independent Models and Studios.</p>
        <span @click="getStarted()" class="cursor-pointer inline-block mt-8 px-8 py-4 bg-white text-purple-700 rounded-full font-bold text-lg md:text-xl lg:text-2xl hover:bg-purple-200">Get Started</span>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                bgImage: `background-image: url('https://images.pexels.com/photos/11146310/pexels-photo-11146310.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`
            }
        },
        methods: {
            getStarted() {
                this.$store.commit('openModal', { mode: 'register', plan: null });
            }
        }
    }
</script>