import { createWebHistory, createRouter } from 'vue-router';
import Home from '@/views/index.vue';
import Dashboard from '@/views/dashboard/index.vue'
import Accounts from '@/views/dashboard/accounts.vue'
import Account from '@/views/dashboard/account.vue'
import Billing from '@/views/dashboard/billing.vue'
import TOS from '@/views/tos.vue'
import Help from '@/views/tutorials.vue'
import Contact from '@/views/contact.vue'
import Privacy from '@/views/privacy.vue'
import NotFound from '@/views/notFound.vue';
import EmailVerify from '@/views/verifyEmail.vue';
import accountRecovery from '@/views/accountRecovery.vue';
import sessionApp from '@/views/dashboard/sessionApp.vue';

const routes = [
	{
		path: '/',name: 'Home',component: Home,
	},
	{
        path: '/dashboard/accounts/:id',name: 'Account',component: Account, meta: {title:"Account "},
		props: () => ({ isNewAccount: false }),
    },
	{
        path: '/dashboard/accounts/new',name: 'New Account',component: Account, meta: {title:"New account "},
		props: () => ({ isNewAccount: true }),
    },
	{
		path: '/dashboard/session',name: 'sessionApp',component: sessionApp, meta: {title:"Alternative option "},
	},
	{
		path: '/terms-of-service',name: 'Terms Of Service',component: TOS,
	},
	{
		path: '/privacy-policy',name: 'Privacy Policy',component: Privacy,
	},
	{
		path: '/contact',name:'Contact',component: Contact
	},
	{
		path: '/help',name: 'Help',component: Help,
	},
	{
        path: '/dashboard',name: 'Dashboard',component: Dashboard,
    },
	{
        path: '/dashboard/accounts',name: 'Accounts',component: Accounts, meta: {title:"Linked accounts "},
    },
	{
        path: '/dashboard/billing',name: 'Billing',component: Billing,
    },
	{
        path: '/verify-email/',name: 'Verify Email',component: EmailVerify,
    },
	{
        path: '/recovery/',name: 'Password recovery',component: accountRecovery,
    },
	{
		path: '/:catchAll(.*)', name: 'not-found', component: NotFound
	},
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return { selector: to.hash }
		}
		return { x: 0, y: 0 }
	}
});

router.beforeEach(async (to,from,next) => {
	var param = `${to.path}`;
	if (param.replace("/","")) {
		let n = param.replace("/","")
		document.title = (to.meta.title==null ? n.charAt(0).toUpperCase() + n.slice(1) : to.meta.title) + ' | PostVerse | Automated Onlyfans share for share';
	} else {
		document.title = 'PostVerse | Automated Onlyfans share for share';
	}
	
    return next();
});

export default router;