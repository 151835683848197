<template>
    <header-section :activeSection="'accounts'" @userData="userData = $event" />

    <div class="mx-2 flex-grow">
        <slot v-if="userData">
            <div class="container mx-auto relative top-0 mt-20 mb-10 rounded p-5 sm:p-10 bg-gray-200" :class="{'mt-28':!userData.verified}">
                <slot v-if="userData && userData.verified">
                    <slot v-if="userData">
                        <section class="mb-8" v-if="userData.account_type == 'studio' || userData.account_type == 'root' || (userData.account_type == 'model' && accounts.length == 0)">
                            <router-link to="/dashboard/accounts/new" class="px-6 py-3 bg-purple-700 text-white rounded hover:bg-purple-600">Add new <i class="fa-solid fa-plus"></i></router-link>
                            <input class="ml-5 border-2 border-gray-300 p-2 mb-4 rounded-md w-max" v-model="searchTerm" type="search" placeholder="Search by name" @input="filterAccounts"/>
                        </section>
                        <section v-else class="mb-5">
                            If you need to add more profiles, please apply for a studio account. Just drop us an email: company@msb.media
                        </section>
                    </slot>
                </slot>
                <div class="text-center text-red-500 text-lg" v-else>Please verify your email first!</div>
                
                <div v-if="!loading && filteredAccounts.length>0" class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4">
                    <div v-for="(model, index) in filteredAccounts" :key="index">
                        <router-link :to="'/dashboard/accounts/'+model._id">
                            <model-card :account="model"></model-card> 
                        </router-link>
                    </div>
                </div>
                <div v-else-if="loading" class="text-center text-xl">
                    <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 gap-4">
                        <model-card-placeholder />
                        <model-card-placeholder />
                        <model-card-placeholder />
                    </div>
                </div>
                <div v-else class="text-center">
                    <span v-if="userData.verified" class="text-lg">Looks like there are no accounts here. <router-link to="/dashboard/accounts/new" class="font-light bg-purple-700 rounded text-white p-2 px-3 shaddow-md"> Add some</router-link></span><br /><br />
                    <img v-if="!userData.verified" :src="pleaseVerifySVG" class="h-128 mb-5 mx-auto" />
                    <img v-else :src="noAccountsFile" class="h-128 mb-5 mx-auto" />
                </div>
            </div>
        </slot>
    </div>
    <footer-section />
</template>

<script setup>
    import modelCard from '@/components/modelCard.vue';
    import modelCardPlaceholder from '@/components/modelCardPlaceholder.vue';
    import headerSection from "@/components/dashboard/header.vue"
    import footerSection from "@/components/footer.vue"
</script>

<script>
    import { get_linked_accounts } from '@/api';
    import NothingHereSVG from "@/assets/images/nothing_here.svg";
    import pleaseVerifySVG from "@/assets/images/verify_email.svg"
    export default {
        props: ['userData'],
        data() {
            return {
                searchTerm: '',
                filteredAccounts: [],
                accounts:[],
                filter:{name:null},
                loading:false,
                noAccountsFile: NothingHereSVG,
                verifysvg: pleaseVerifySVG,
                user:{}
            }
        },
        methods: {
            filterAccounts() {
                if (this.searchTerm !== '') {
                    this.filteredAccounts = this.accounts.filter(account =>
                    account.name.toLowerCase().includes(this.searchTerm.toLowerCase())
                    );
                } else {
                    this.filteredAccounts = this.accounts;
                }
            },
            formatCategoryTitle(category) {
                return category
                    .replace(/_/g, ' ') // Replace underscores with spaces (for snake_case)
                    .replace(/([A-Z])/g, ' $1') // Insert a space before each uppercase letter (for camelCase)
                    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase()) // Capitalize the first letter of each word
                    .trim().toUpperCase(); // Remove any leading or trailing spaces
            },
            categoryClass(category) {
                return category == "failed_verification" ? 'text-red-400' : (category == "ready_for_posting" ? 'text-green-600' : (category == "waiting_for_verification" ? 'text-orange-300' : 'text-black'));
            }
        },
        components: {headerSection,modelCard,footerSection,modelCardPlaceholder},
        async mounted() {
            this.loading = true;
            const accounts = await get_linked_accounts(this.$axios,this.$cookies.get("auth-token"));
            if (accounts && accounts.success) {
                this.accounts = accounts.models
                this.filteredAccounts = accounts.models
                this.loading = false;
            } else {
                this.loading = false;
                console.log("You should log out");
            }
        }
    }
</script>