<template>
  <section class="bg-white py-12 px-6">
    <div class="container mx-auto text-center">
      <h2 class="text-purple-700 text-3xl md:text-4xl font-semibold mb-6">Why PostVERSE?</h2>
      <p class="text-gray-600 text-lg md:text-xl mb-12 max-w-3xl mx-auto">
        PostVerse is a unique platform that harnesses the power of share-for-share marketing, specifically designed for Onlyfans models and studios. With our automated system, you can effortlessly increase your visibility and grow your fan base. Join us today and experience the growth and success that so many of our users already have.
      </p>
      <!-- Replace with your icons or illustrations -->
      <div class="flex flex-wrap">
        <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-4">
          <i class="fas fa-rocket text-purple-700 text-5xl mb-4"></i>
          <h3 class="text-xl font-semibold text-gray-700 mb-2">Fast Growth</h3>
          <p class="text-gray-500">Experience rapid growth with share-for-share marketing.</p>
        </div>
        <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-4">
          <i class="fas fa-users text-purple-700 text-5xl mb-4"></i>
          <h3 class="text-xl font-semibold text-gray-700 mb-2">Larger Audience</h3>
          <p class="text-gray-500">Expand your reach and get more fans with our platform.</p>
        </div>
        <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-4">
          <i class="fas fa-cogs text-purple-700 text-5xl mb-4"></i>
          <h3 class="text-xl font-semibold text-gray-700 mb-2">Automation</h3>
          <p class="text-gray-500">Our system handles the sharing process, saving you time.</p>
        </div>
        <div class="w-full sm:w-1/2 md:w-1/3 lg:w-1/4 px-2 mb-4">
          <i class="fas fa-star text-purple-700 text-5xl mb-4"></i>
          <h3 class="text-xl font-semibold text-gray-700 mb-2">Quality Service</h3>
          <p class="text-gray-500">Enjoy quality service from our dedicated team.</p>
        </div>
      </div>
    </div>
  </section>
</template>
