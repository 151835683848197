<template>
  <footer class="bg-purple-900 py-6 text-white">
    <div class="container px-10 mx-auto text-center md:text-left">
      <div class="flex flex-wrap justify-center md:justify-between items-center">
        <!-- Links -->
        <div class="w-full md:w-auto mb-4 md:mb-0">
          <ul class="list-none">
            <li class="inline-block mr-4">
              <router-link to="/terms-of-service" class="text-purple-300 hover:text-white">Terms of Service</router-link>
            </li>
            <li class="inline-block mr-4">
              <router-link to="/privacy-policy" class="text-purple-300 hover:text-white">Privacy Policy</router-link>
            </li>
            <li class="inline-block">
              <router-link to="/contact" class="text-purple-300 hover:text-white">Contact Us</router-link>
            </li>
          </ul>
        </div>
        <!-- Social media icons -->
        <div class="w-full md:w-auto hidden">
          <a href="#" class="text-purple-300 hover:text-white mr-4"><i class="fab fa-facebook-square fa-2x"></i></a>
          <a href="#" class="text-purple-300 hover:text-white mr-4"><i class="fab fa-twitter-square fa-2x"></i></a>
          <a href="#" class="text-purple-300 hover:text-white"><i class="fab fa-instagram-square fa-2x"></i></a>
        </div>
      </div>
      <!-- Copyright -->
      <p class="text-purple-500 mt-4 text-sm">© 2023 PostVerse. All Rights Reserved.</p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>

<style scoped>
.footer a {
  transition: color 0.3s ease;
}
</style>
