<template>
    <div v-if="account" class="hover:drop-shadow-xl hover:border-gray-500 relative w-full h-32 cursor-pointer border bg-white rounded-2xl p-5 py-2" :class="'border-'+statusColor">
        <div slot="header" class="flex justify-between items-center"  @mouseenter="showTooltip = true" @mouseleave="showTooltip = false">
            <div :style="`background-color:silver;background-image:url('${account.avatar}')`" class="w-24 h-full absolute left-0 top-0 rounded-l-2xl mr-5 bg-cover bg-center">
                <div class="mt-0 p-2 z-10 ml-16">
                    <div :class="'bg-'+statusColor" class="absolute text-white rounded-full w-max text-sm p-1 px-3 -top-4 right-1 z-20" v-if="showTooltip">{{tooltopText}}</div>
                </div>
            </div>
            <div class="leading-5 ml-20 pl-2">
                <div class="w-42 block truncate font-bold overflow-hidden text-lg">{{account.name}}</div>
                <small v-if="account.username">@{{account.username}}</small><br />
            </div>
            <i @click.stop.prevent="turnOffMarketing()" class="cursor-pointer fa-solid fa-xl -mt-5 -mr-2" :class="{'fa-toggle-off text-gray-700':!account.marketing_on,'fa-toggle-on text-green-600':account.marketing_on}"></i>
        </div>
        <div class="mt-3 leading-5 ml-20 pl-2">
            <section class="grid grid-cols-2 gap-3 text-center">
                <div v-if="account.fans_active" class="bg-gray-100 rounded-2xl py-1">
                    <span class="text-xs">Fans</span><br />
                    <span class="font-semibold text-sm"><span class="font-light">~</span>{{account.fans_active.toLocaleString('en-US').replace(',', ' ')}}</span>
                </div>
                <div v-if="account.content" class="bg-gray-100 rounded-2xl py-1">
                    <span class="text-xs">Images</span><br />
                    <span class="font-semibold text-sm">{{account.content.images}}</span>
                </div>
                <div v-else-if="this.account.verified" class="bg-red-200 rounded-2xl px-3 py-1 flex justify-center items-center h-full">
                    <span class="text-xs">Add content</span>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
    import { marketing_on_off } from '@/api';
    export default {
        props: ['account'],
        computed: {
            status() {
                return this.account.verified ? '<i class="fa-regular fa-circle-check text-green-600"></i>' : (!this.account.verified && this.account.verification_report ? '<i class="fa-solid fa-triangle-exclamation text-orange-700"></i>' : '<i class="fa-solid fa-spinner animate-spin text-orange-600"></i>');
            },
            statusColor() {
                return this.account.verified ? 'green-600' : (!this.account.verified && this.account.verification_report ? 'red-400' : 'red-300');
            },
            statusIcon() {
                return this.account.verified ? 'fa-regular fa-circle-check' : (!this.account.verified && this.account.verification_report ? 'fa-solid fa-triangle-exclamation' : 'fa-solid fa-spinner animate-spin');
            },
            needsAttention() {
                // Add condition here to identify accounts that are verified but have no images
                return this.account.verified && (!this.account.content || !this.account.content.images || this.account.content.images.length === 0);
            },
            tooltopText() {
                return this.account.verified ? 'Verified' : (!this.account.verified && this.account.verification_report ? 'Verification failed' : 'Waiting for approval');
            }
        },
        data() {
            return {
                showTooltip:false
            }
        },
        methods: {
            async turnOffMarketing() {
                if (this.account.marketing_on == true) {
                    if (!confirm("Please confirm stopping promotion on this account")) {
                        return;
                    }
                }
                
                let status = await marketing_on_off(this.$axios,this.$cookies.get("auth-token"),this.account._id,!this.account.marketing_on);
                if (status.success == true) {
                    this.account.marketing_on = !this.account.marketing_on;
                }
            }
        }
    }
</script>

<style scoped>
    .imageWithOverlay::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 50%;
        background: linear-gradient(to left, rgba(255,255,255,0.4), transparent);
        border-radius: inherit;
        pointer-events: none;
        z-index: 5;
    }
</style>