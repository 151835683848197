<template>
    <div v-if="loading" class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white">
        Verifying
        <i class="ml-3 fa-solid fa-circle-notch animate-spin fa-2xl"></i>
    </div>
    <div v-else class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white text-lg">
        {{message}}
    </div>
    <section v-if="change" class="relative mt-20 bg-white w-full sm:w-96 mx-auto h-max p-5 rounded z-10 justify-center items-center">
        <label class="text-sm text-gray-600 font-semibold">New password</label>
        <input v-model="recovery.password" class="border-2 border-gray-300 p-2 mb-4 rounded-md w-full" :type="!showPass?'password':'text'" placeholder="Password">
        <i @click="showPass = !showPass" :class="{'fa-eye-slash':showPass,'fa-eye':!showPass}" class="fa-regular absolute right-9 mt-3.5 cursor-pointer"></i>
        <label class="text-sm text-gray-600 font-semibold">Confirm password</label>
        <input v-model="recovery.passwordCheck" class="border-2 border-gray-300 p-2 mb-4 rounded-md w-full" :type="!showPass?'password':'text'" placeholder="Confirm Password">
        <i @click="showPass = !showPass" :class="{'fa-eye-slash':showPass,'fa-eye':!showPass}" class="fa-regular absolute right-9 mt-3.5 cursor-pointer"></i>
        <div class="text-center mb-2 text-red-600 text-sm -mt-2">{{err_message}}</div>
        <button @click="saveNewPass()" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm  py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm">
            <i v-if="btnloading" class="fa-solid fa-spinner animate-spin mt-0.5 mr-2" :class="{'mr-16':modalMode === 'login','mr-20':modalMode === 'register'}"></i> Set password
        </button>
    </section>
</template>

<script>
    import { user_api } from '@/api.js'
    export default {
        data() {
            return {
                message:'Something went wrong',
                change:false,
                showPass:false,
                loading:true,
                btnloading:false,
                recovery: {
                    password:null,
                    passwordCheck:null
                },
                token:null,
                err_message:null
            }
        },
        methods: {
            async saveNewPass() {
                this.btnloading = true;
                let formData = {
                    token: this.token,
                    password: this.recovery.password,
                    passwordCheck: this.recovery.passwordCheck
                }
                try {
                    const changedPass = await user_api(this.$axios,formData,'new_pass');
                    if (changedPass) {
                        this.btnloading = false;
                        if (changedPass.success === true) {
                            this.err_message = null
                            this.$cookies.set('auth-token',changedPass.token);
                            setTimeout(() => {
                                this.$router.push("../dashboard");
                            },200);
                        } else {
                            this.err_message = changedPass.message
                        }
                    }
                } catch (e) {

                }
            }
        },
        async mounted() {
            console.log(this.$route.query);
            this.token = this.$route.query.token;
            if (!this.$route.query || !this.token) {this.$router.push('../404');}

            const tokenVerification = await user_api(this.$axios,this.token,'recovery_token')
            this.loading = false;
            if (tokenVerification.success === true) {
                this.message = '';
                this.change = true;
            } else {
                this.message = tokenVerification.message + '.  You will be redirected automatically';
                setTimeout(() => {
                    this.$router.push({name:'Home'});
                }, 2000);
            }
        }
    }
</script>