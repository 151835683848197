<template>
    <header-section @scroll-to-section="scrollTo" />
    <div class="p-10 text-base text-gray-800 bg-white container mx-auto mt-20">
        <h1 class="text-4xl font-bold mb-4">Terms of Service</h1>

        <h2 class="text-2xl mt-6 mb-2">1. Agreement to Terms</h2>
        <p>By using our Services, you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, do not use the Services.</p>

        <h2 class="text-2xl mt-6 mb-2">2. Changes to Terms or Services</h2>
        <p>We may update the Terms at any time, in our sole discretion. We will notify you of any updates.</p>

        <h2 class="text-2xl mt-6 mb-2">3. Privacy Policy</h2>
        <p>Please refer to our Privacy Policy for information on how we collect, use, and disclose information from our users.</p>

        <h2 class="text-2xl mt-6 mb-2">4. Account Registration and Use</h2>
        <p>You must be at least 18 years old to use our Services. By using our Services, you state that:</p>
        <ul class="list-disc ml-5">
        <li>You can form a binding contract with PostVerse.</li>
        <li>You will comply with these Terms and all applicable local, state, national, and international laws, rules, and regulations.</li>
        </ul>

        <h2 class="text-2xl mt-6 mb-2">5. Your Content and Conduct</h2>
        <p>Our Services allow you and other users to post, link, store, share and otherwise make available certain information, text, graphics, videos, or other material. You are responsible for the content that you post to the Service, including its legality, reliability, and appropriateness.</p>

        <h2 class="text-2xl mt-6 mb-2">6. Prohibited Conduct</h2>
        <p>You agree not to engage in any conduct that is harmful to PostVerse's operations or reputation, or conduct that violates any laws or regulations.</p>

        <h2 class="text-2xl mt-6 mb-2">7. Termination of Account</h2>
        <p>We may terminate or suspend your account at any time, for any reason, and without advance notice.</p>

        <h2 class="text-2xl mt-6 mb-2">8. Limitation of Liability</h2>
        <p>PostVerse will not be responsible for any damages or losses arising from your use or inability to use the Service, or otherwise in connection with the Service.</p>

        <h2 class="text-2xl mt-6 mb-2">9. Governing Law</h2>
        <p>These Terms will be governed by the laws of the Slovak Republic.</p>
    </div>
    <footer-section  class="mt-5"/>
</template>

<script setup>
    import headerSection from "@/components/header.vue"
    import footerSection from "@/components/footer.vue"
</script>

<script>
export default {
    name: 'TermsOfService',
    components: {headerSection,footerSection},
    mounted() {
        window.scrollTo(0, 0);
    }
}
</script>
