// src/api.js

export async function verifySession(axiosInstance,auth_token) {
    if (auth_token) {
        try {
            const response = await axiosInstance.post(`${import.meta.env.VITE_API_ENDPOINT}/user/`, {
                action: 'auth',
                auth_token: auth_token,
            });
            return response.data;
        } catch (error) {
            console.error('Failed to verify session:', error);
            return false;
        }
    }
    return false;
}

export async function get_account_credentials(axiosInstance,auth_token,password,model) {
    if (auth_token) {
        try {
            const response = await axiosInstance.post(`${import.meta.env.VITE_API_ENDPOINT}/model/`, {
                action: 'account_credentials',
                auth_token: auth_token,
                password: password,
                model_id: model
            });
            return response.data;
        } catch (error) {
            console.error('Failed to verify session:', error);
            return false;
        }
    }
    return false;
}

export async function verify_email(axiosInstance,token) {
    try {
        const response = await axiosInstance.post(`${import.meta.env.VITE_API_ENDPOINT}/user/`, {
            action: 'verify_email',
            token: token
        });
        return response.data;
    } catch (err) {
        console.log(err);
    }
    return true;
}

export async function update_credentials(axiosInstance,auth_token,credentials,model) {
    if (auth_token) {
        try {
            const response = await axiosInstance.post(`${import.meta.env.VITE_API_ENDPOINT}/model/`, {
                action: 'update_credentials',
                auth_token: auth_token,
                credentials: credentials,
                model_id: model
            });
            return response.data;
        } catch (error) {
            console.error('Failed to verify session:', error);
            return false;
        }
    }
    return false;
}

export async function resend_email(axiosInstance,auth_token) {
    if (auth_token) {
        try {
            const response = await axiosInstance.post(`${import.meta.env.VITE_API_ENDPOINT}/user/`, {
                action: 'resend_email',
                auth_token: auth_token
            });
            return response.data;
        } catch (error) {
            console.error('Failed to verify session:', error);
            return false;
        }
    }
    return false;
}

export async function get_linked_accounts(axiosInstance,auth_token) {
    if (auth_token) {
        try {
            const response = await axiosInstance.get(`${import.meta.env.VITE_API_ENDPOINT}/model/?action=get_models&auth_token=${auth_token}`);
            return response.data;
        } catch (error) {
            return false;
        }
    }
    return false;
}

export async function marketing_on_off(axiosInstance,auth_token,account_id,newValue) {
    if (auth_token && account_id) {
        try {
            const response = await axiosInstance.post(`${import.meta.env.VITE_API_ENDPOINT}/model/`, {
                action: 'marketing_on_off',
                auth_token: auth_token,
                account_id: account_id,
                marketing: newValue
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }
}

export async function get_account_by_id(axiosInstance,auth_token,account_id) {
    if (auth_token && account_id) {
        try {
            const response = await axiosInstance.get(`${import.meta.env.VITE_API_ENDPOINT}/model/?action=get_model_by_id&account_id=${account_id}&auth_token=${auth_token}`);
            return response.data;
        } catch (error) {
            return false;
        }
    }
    return false;
}

export async function user_api(axiosInstance,data,action) {
    try {
        const response = await axiosInstance.post(`${import.meta.env.VITE_API_ENDPOINT}/user/`, {
            action: action,
            formData: data
        });
        return response.data??false;
    } catch (error) {
        console.error('Error during login:', error);
        return false;
    }
}

export async function logout_api(axiosInstance,auth_token) {
    if (auth_token) {
        try {
            const response = await axiosInstance.post(`${import.meta.env.VITE_API_ENDPOINT}/user/`, {
                action: 'logout',
                auth_token: auth_token,
            });
            return response.data;
        } catch (error) {
            return false;
        }
    }
    return false;
}