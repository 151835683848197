<template>
    
    <header-section :activeSection="'billing'" @userData="userData = $event" />
    <div class="mx-2 flex-grow">
        <div class="container mx-auto relative top-0 mt-20 mb-10 rounded p-5 sm:p-10 bg-gray-200">
            <h1 class="text-3xl font-bold">Plans and billing</h1>
            <h3 class="text-sm mt-1">Manage your plans and billing details</h3>

            <br />
            <slot v-if="userData && userData.account_type == 'model'">
                <pricing-section page="dashboard" :plan="$route.query.plan" />
            </slot>
            <slot v-else>
                Payment using invoices
            </slot>
        </div>
    </div>
    <footer-section />
</template>

<script setup>
    import headerSection from "@/components/dashboard/header.vue"
    import pricingSection from "@/components/pricing.vue"
    import footerSection from "@/components/footer.vue"
</script>

<script>
    export default {
        components: {headerSection,pricingSection,footerSection},
        props: ['userData'],
    }
</script>