<template>
  <div>
    <div class="flex flex-col min-h-screen">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, onBeforeUnmount, computed } from 'vue';

export default {
  setup() {
    const scrollY = ref(0);
    const updateScroll = () => {
      scrollY.value = window.scrollY;
    };

    onMounted(() => {
      window.addEventListener('scroll', updateScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', updateScroll);
    });

    const activeSection = computed(() => {
      if (scrollY.value >= document.getElementById('footer').offsetTop) {
        return 'footer';
      } else if (scrollY.value >= document.getElementById('pricing').offsetTop) {
        return 'pricing';
      } else if (scrollY.value >= document.getElementById('about').offsetTop) {
        return 'about';
      } else {
        return 'home';
      }
    });

    return { activeSection };
  },
}
</script>