<template>
    <div v-if="loading" class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white">
        Verifying
        <i class="ml-3 fa-solid fa-circle-notch animate-spin fa-2xl"></i>
    </div>
    <div v-else class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 text-white text-lg">
        {{message}}
    </div>
</template>

<script>
    import { verify_email } from '@/api.js'
    export default {
        data() {
            return {
                message:'Something went wrong',
                loading:true
            }
        },
        async mounted() {
            if (!this.$route.query || !this.$route.query.token) {this.$router.push('../404');}

            const verification = await verify_email(this.$axios,this.$route.query.token)
            if (verification) {this.loading = false;}
            if (verification.success === true) {
                this.message = 'Great, email was VERIFIED! You will be redirected automatically.';
                setTimeout(() => {
                    this.$router.push({name:'Home'});
                }, 2000);
            } else {    
                this.message = 'This link has expired. Please contact us if you need assistance. You will be redirected automatically';
                setTimeout(() => {
                    this.$router.push({name:'Home'});
                }, 2000);
            }
        }
    }
</script>