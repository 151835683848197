<template>
  <header class="bg-white shadow-md fixed top-0 w-screen z-20">
    <div class="container mx-auto flex items-center justify-between px-6 py-3">
      <div @click="scrollToSection('home'),activeSection='home'">
        <router-link to="../" class="font-semibold text-purple-700 text-xl leading-none">
          PostVERSE
          <h4 class="font-normal text-xs">Your Success, Automated!</h4>
        </router-link>
      </div>
      <nav class="md:flex space-x-10 hidden">
        <a :class="{ 'text-purple-700': activeSection === 'about' }" class="cursor-pointer text-gray-500 hover:text-purple-600" @click="scrollToSection('about'),activeSection='about'" >
          About
        </a>
        <a :class="{ 'text-purple-700': activeSection === 'pricing' }" class="cursor-pointer text-gray-500 hover:text-purple-600" @click="scrollToSection('pricing'),activeSection='pricing'" >
          Pricing
        </a>
        <a :class="{ 'text-purple-700': activeSection === 'faq' }" class="cursor-pointer text-gray-500 hover:text-purple-600" @click="scrollToSection('faq'),activeSection='faq'" >
          FAQ
        </a>
        <a class="cursor-pointer text-gray-500 hover:text-purple-600" @click.prevent="openLogin()">Login/Register</a>
      </nav>
      <div class="md:hidden">
        <button @click="isOpen = !isOpen" class="focus:outline-none">
          <i class="fas fa-bars text-purple-700 text-xl"></i>
        </button>
      </div>
    </div>
    <div v-if="isOpen" class="absolute top-0 left-0 w-full h-screen bg-purple-700 bg-opacity-95 text-white text-2xl z-10 px-6 py-3">
        <div class="container mx-auto flex justify-between items-center">
            <div class="font-semibold text-white text-xl">
              PostVERSE
              <h4 class="font-normal text-xs">Your Success, Automated!</h4>
            </div>
            <button @click="isOpen = !isOpen" class="focus:outline-none">
                <i class="fas fa-times text-white text-2xl"></i>
            </button>
        </div>
        <nav class="mt-8 font-bold leading-loose text-right">
            <a class="block cursor-pointer text-white hover:text-purple-300" @click="closeAndNavigate('about')">About</a>
            <a class="block cursor-pointer text-white hover:text-purple-300" @click="closeAndNavigate('faq')">FAQ</a>
            <a class="block cursor-pointer text-white hover:text-purple-300" @click="closeAndNavigate('pricing')">Pricing</a>
            <a class="block cursor-pointer text-white hover:text-purple-300" @click.prevent="openLogin()">Login/Register</a>
        </nav>
    </div>
    <login-register-modal />
  </header>
</template>

<script setup>
    import LoginRegisterModal from '@/components/loginRegisterModal.vue';
</script>

<script>
import { verifySession } from '@/api';
export default {
    props: ['activeSection'],
    data() {
        return {
            isOpen: false,
        }
    },
    components: {LoginRegisterModal},
    methods: {
        closeAndNavigate(section) {
            this.isOpen = false;
            this.scrollToSection(section);
        },
        scrollToSection(section) {
          console.log("hello, scroll to: ",section);
          if (this.$route.path != "/") {
            this.$router.push('/');
          }
          this.$emit('scroll-to-section', section);
        },
        async openLogin() {
          let logged = await verifySession(this.$axios,this.$cookies.get("auth-token"));
          this.$emit('userData',logged)
          if (logged.success) {
            this.$router.push("../dashboard");
          }
          this.$store.commit('openModal', { mode: 'login', plan: null });
        },
        openRegistration() {
          this.$store.commit('openModal', { mode: 'register', plan: null });
        }
    }
}
</script>
