<template>
    <div class="flex flex-col min-h-screen">
        <header-section />
        <div class="mx-2 flex-grow">
            <div class="container mx-auto relative top-0 mt-20 mb-10 rounded p-5 sm:p-10 bg-gray-200">
                <div @click="$router.push('accounts')" class="cursor-pointer border rounded border-black mb-7 w-max p-2 px-4">
                    <i class="fa-solid fa-arrow-left mr-2"></i>Back
                </div>
                <h1 class="text-2xl font-bold">An Alternative Access Path - For Those Who Need It</h1>

                <p class="mt-6">At our company, we prioritize your comfort and trust. We aim to establish a secure and direct connection between your account and our software, best achieved by you providing us with your login credentials. This approach ensures smooth integration, seamless interaction, and the best possible user experience.</p>
                <p class="mt-6">However, we understand that each user's comfort level varies, and some may prefer not to share their credentials directly. For those users, we've developed an alternative solution.</p>
                <p class="mt-6">As a last resort, we have created a special app. This app serves as a bridge between your account and our software, handling your login session on your device without the need to share credentials with us. While this approach might not offer the seamless experience of our preferred method, it still allows you to use our software while maintaining full control over your credentials.</p>

                <h3 class="text-lg font-bold mt-6 mb-2">Here's how it works:</h3>

                <ul class="list-disc ml-7 leading-looseer">
                    <li>Download and install our app on your device.</li>
                    <li>Log into your account within this app.</li>
                    <li>Establish a connection to our software.</li>
                </ul>
                <p class="mt-6">Remember, your login credentials never leave your device and are not shared with us.</p>
                <p class="mt-6">Though we assure you of our platform's high level of security and data protection, we respect that some users may require this alternate method. We're committed to providing options to suit everyone's comfort level while using our software.</p>
                <p class="mt-6">Please consider this alternate method as a backup plan, rather than the go-to solution. We genuinely believe that the direct method provides you with a more seamless and enjoyable experience.</p>

                <section class="grid grid-cols-2 gap-5 mt-2 w-max mx-auto">
                    <section class="cursor-pointer mt-6 shadow-lg bg-purple-600 text-center rounded p-2 px-4 text-white transform hover:scale-105 transition-all duration-300">
                        <i class="fa-brands fa-windows mr-1.5"></i> Download for Windows
                    </section>
                    <section class="cursor-pointer mt-6 shadow-lg border-2 border-purple-600 text-purple-600 text-center rounded p-2 px-4 transform hover:scale-105 transition-all duration-300">
                        <i class="fa-brands fa-apple mr-1.5"></i> Download for Mac
                    </section>
                </section>
            </div>
        </div>
        <footer-section />
    </div>
</template>

<script setup>
    import headerSection from "@/components/dashboard/header.vue"
    import heroSection from "@/components/dashboard/hero.vue"
    import footerSection from "@/components/footer.vue"
</script>

<script>
    export default {
        components: {headerSection,heroSection,footerSection},
        mounted() {
            window.scrollTo(0, 0);
        }
    }
</script>