<template>
  <section class="bg-white py-12 px-6">
    <div class="container mx-auto text-center">
      <h2 class="text-purple-700 text-3xl md:text-4xl font-semibold mb-6">Frequently Asked Questions</h2>
      <div class="text-left max-w-4xl mx-auto">
        <div class="mb-5 rounded-md border-2 border-gray-200 overflow-hidden" v-for="(item, index) in faqItems" :key="index">
          <h3 class="text-xl font-semibold text-gray-700 bg-gray-100 p-4 flex justify-between items-center cursor-pointer" @click="toggle(index)">
            <span>{{ item.question }}</span>
            <i :class="`fas fa-chevron-${item.isOpen ? 'up' : 'down'} text-purple-700`"></i>
          </h3>
          <transition name="fade-slide">
            <p class="text-gray-500 p-4" v-show="item.isOpen">{{ item.answer }}</p>
          </transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      faqItems: [
        {
          question: 'What is Share-for-Share marketing?',
          answer: 'Share-for-Share marketing is a strategy where two creators agree to promote each other\'s content to their respective fan bases. It\'s a cost-effective way to reach a larger audience, as both creators benefit from the added exposure.',
          isOpen: false
        },
        {
          question: 'How does the automated system work?',
          answer: 'Our automated system takes the hassle out of manual share-for-share processes. You simply specify the content you want to share, and our system will handle the rest. It will automatically share your content to partnered accounts and similarly, share their content on your account.',
          isOpen: false
        },
        {
          question: 'How can I grow my fan base with PostVERSE?',
          answer: 'With PostVERSE, you can reach a larger audience than you would on your own. Our platform enables you to tap into the fan bases of your partners, increasing your visibility and attracting more fans to your content.',
          isOpen: false
        },
        {
          question: 'What\'s included in the different pricing plans?',
          answer: 'Each of our plans is designed to cater to different usage needs. The Basic Plan is suitable for accounts that post up to 50 times a day and need basic support. The Standard Plan increases the post limit to 150 posts per day and includes priority support. The Pro Plan offers the highest post limit at 300 posts per day, and includes premium support.',
          isOpen: false
        },
        {
          question: 'Is my data secure with PostVERSE?',
          answer: 'Absolutely. We take data security very seriously. We have robust security measures in place to protect your personal information and content. We do not share your data with any third parties without your explicit consent.',
          isOpen: false
        },
        {
          question: 'What is your privacy policy?',
          answer: 'We respect your privacy and are committed to protecting your personal information. Our privacy policy details how we collect, use, and safeguard your information. We encourage you to review it to understand our practices.',
          isOpen: false
        },
        {
          question: 'How does the free trial work?',
          answer: 'Our 14-day free trial gives you access to all the features of the Pro Plan. This includes up to 300 posts per day and our automated share-for-share feature. You can cancel at any time during the trial period at no cost.',
          isOpen: false
        }
      ]
    };
  },
  methods: {
    toggle(index) {
      this.faqItems[index].isOpen = !this.faqItems[index].isOpen;
    }
  }
};
</script>

<style>
    .fade-slide-enter-active, .fade-slide-leave-active {
        transition: all .3s ease;
    }
    .fade-slide-enter, .fade-slide-leave-to {
        opacity: 0;
        transform: translateY(-10px);
    }
    .fade-slide-enter-to, .fade-slide-leave {
        opacity: 1;
        transform: translateY(0);
    }
</style>
