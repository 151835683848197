<template>
  <section class="bg-purple-100 py-12 px-6 items-center justify-center flex">
    <section class="absolute text-purple-700 text-center mx-auto w-full font-bold text-4xl z-10">
        Free beta version till further notice<br /><br />
        <button @click="choosePlan('plan_V39VROnY4tp0miDUkybd6qOl')" class="cursor-pointer bg-purple-700 text-white py-2 px-4 rounded">Start a Free Trial</button>
    </section>
    <div class="container mx-auto text-center opacity-5">
      <h2 v-if="page != 'dashboard'" class="text-purple-700 text-3xl md:text-4xl font-semibold mb-6">Flexible Pricing for Your Needs</h2>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
        <!-- Basic Plan -->
        <div class="w-full p-4">
          <div class="bg-white shadow rounded p-6">
            <h3 class="text-purple-700 text-2xl font-semibold mb-4">Basic Plan</h3>
            <p class="text-purple-500 text-6xl font-bold mb-4">$100</p>
            <p class="text-gray-500 mb-4">Billed monthly per account</p>
            <ul class="text-gray-600 mb-4">
              <li>Up to 50 Posts per Day</li>
              <li>Automated Share-for-Share</li>
              <li>Basic Support</li>
            </ul>
            <button @click="choosePlan('plan_j0Ndqj6uIEdXFEYXOLlpn1al')" class="bg-purple-700 text-white py-2 px-4 rounded">Choose Plan</button>
          </div>
        </div>
        <!-- Standard Plan -->
        <div class="w-full p-4">
          <div class="bg-white shadow rounded p-6">
            <h3 class="text-purple-700 text-2xl font-semibold mb-4">Standard Plan</h3>
            <p class="text-purple-500 text-6xl font-bold mb-4">$175</p>
            <p class="text-gray-500 mb-4">Billed monthly per account</p>
            <ul class="text-gray-600 mb-4">
              <li>Up to 150 Posts per Day</li>
              <li>Automated Share-for-Share</li>
              <li>Priority Support</li>
            </ul>
            <button @click="choosePlan('plan_VaCmpHWH9EzB51dZMJpshXJj')" class="bg-purple-700 text-white py-2 px-4 rounded">Choose Plan</button>
          </div>
        </div>
        <!-- Pro Plan -->
        <div class="w-full p-4">
          <div class="bg-white shadow rounded p-6">
            <h3 class="text-purple-700 text-2xl font-semibold mb-4">Pro Plan</h3>
            <p class="text-purple-500 text-6xl font-bold mb-4">$250</p>
            <p class="text-gray-500 mb-4">Billed monthly per account</p>
            <ul class="text-gray-600 mb-4">
              <li>Up to 300 Posts per Day</li>
              <li>Automated Share-for-Share</li>
              <li>Premium Support</li>
            </ul>
            <button @click="choosePlan('plan_LKjIdB3KAoWpITWF9fQpLELU')" class="bg-purple-700 text-white py-2 px-4 rounded">Choose Plan</button>
          </div>
        </div>
        <!-- Free Trial -->
        <div class="w-full p-4">
          <div class="bg-white shadow rounded p-6">
            <h3 class="text-purple-700 text-2xl font-semibold mb-4">Free Trial</h3>
            <p class="text-purple-500 text-6xl font-bold mb-4">14 Days</p>
            <p class="text-gray-500 mb-4">Access to Pro Plan Features</p>
            <ul class="text-gray-600 mb-4">
                <li>Up to 300 Posts per Day</li>
                <li>Automated Share-for-Share</li>
                <br />
            </ul>
            <button @click="choosePlan('plan_V39VROnY4tp0miDUkybd6qOl')" class="bg-purple-700 text-white py-2 px-4 rounded">Start Free Trial</button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['page','plan'],
  data() {
    return {
      checkoutRequest: ''
    }
  },
  methods: {
    choosePlan(planId) {
      this.$store.commit('openModal', { mode: 'register', plan: planId });
      if (this.page == 'dashboard') {
        this.initiatePayment(planId)
      }
    },
    initiatePayment(planId) {
      // Assuming you have a route in your PHP backend to create and sign the Checkout request
      this.$axios.post(`${import.meta.env.VITE_API_ENDPOINT}/create-checkout/`,{
        planID: planId,
        auth_token: this.$cookies.get("auth-token")
      })
        .then(response => {
          this.checkoutRequest = response.data.checkoutRequest;
          this.openCheckout(planId);
        })
        .catch(error => console.log(error));
    },
    openCheckout(planId) {
      Shift4Checkout.key = import.meta.env.VITE_SHIFT4_KEY;
      Shift4Checkout.success = function (result) {
        // handle successful payment
      };
      Shift4Checkout.error = function (errorMessage) {
        // handle integration errors
        console.log(errorMessage);
      };
      Shift4Checkout.open({
        checkoutRequest: this.checkoutRequest,
        name: 'Shift4',
        description: 'PostVerse checkout'
      });
    }
  },
  mounted() {
    if (this.plan != null) {
      this.initiatePayment(this.plan)
    }
  }
}
</script>
