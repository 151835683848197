<template>
  <div v-if="!verifiedEmail" class="w-full text-center bg-red-300 z-30 top-0 fixed py-1">
    <slot v-if="!resending"><strong>Verify your email address.</strong> 
      <span @click="resend()" class="underline cursor-pointer ml-2">Re-send email.</span>
    </slot>
    <span v-else>Email was sent again.</span>
  </div>
  <header class="bg-purple-900 shadow-md fixed top-0 w-screen z-10" :class="{'top-8':!verifiedEmail}">
    <div class="container mx-auto flex items-center justify-between px-6 py-3 text-white">
      <div class="flex items-center space-x-4 md:space-x-6">
        <router-link to="/dashboard" class="font-semibold text-gray-100 text-xl leading-none">
            PostVERSE
            <h4 class="font-normal text-xs hidden sm:block">Your Success, Automated!</h4>
          </router-link>
      </div>

      <nav class="hidden md:flex space-x-6 items-center">
          <router-link to="/dashboard/accounts" :class="{'text-purple-400':$route.name=='Accounts'}" class="hover:text-purple-500">Accounts</router-link>
          <router-link to="/dashboard/billing" :class="{'text-purple-400':$route.name=='Billing'}" class="hover:text-purple-500">Billing</router-link>
          <button @click="logout" class="px-4 py-2 rounded bg-purple-500 hover:bg-purple-600 text-white">Logout</button>
      </nav>

      <div class="md:hidden flex w-1/2 justify-around">
        <router-link to="/dashboard/accounts" :class="{'text-purple-400':$route.name=='Accounts'}"><i class="fas fa-user fa-lg"></i></router-link>
        <router-link to="/dashboard/billing" :class="{'text-purple-400':$route.name=='Billing'}"><i class="fas fa-credit-card fa-lg"></i></router-link>
        <button @click="logout"><i class="fas fa-sign-out-alt fa-lg"></i></button>
      </div>
    </div>
  </header>
</template>

<script>
import { logout_api,verifySession,resend_email } from '@/api.js'
export default {
    props: ['activeSection'],
    data() {
      return {
        verifiedEmail:true,
        resending:false
      }
    },
    emits: ['userData'],
    methods: {
      async logout() {
        const logoutStatus = await logout_api(this.$axios,this.$cookies.get('auth-token'));
        if (logoutStatus.success) {
            this.$cookies.remove("auth-token");
            console.log(this.$route);
            this.$router.push({path: "../", query: {returnLink: this.$route.fullPath}});
        }
      },
      async resend() {
        this.resending = true;
        const re_email = await resend_email(this.$axios,this.$cookies.get('auth-token'));
      }
    },
    async mounted() {
      let logged = await verifySession(this.$axios,this.$cookies.get("auth-token"));
      if (logged && (logged.verified === false)) {
        this.verifiedEmail = false;
      }
      this.$emit('userData',logged)
      if (!logged.success) {
          this.$cookies.remove("auth-token");
          console.log(this.$route);
          this.$router.push({path: "../", query: {returnLink: this.$route.fullPath}});
      }
    }
};
</script>
