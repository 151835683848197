import { createApp } from 'vue'
import '@/assets/css/index.css'
import App from './App.vue'
import axios from 'axios';
import { createStore } from 'vuex'
import router from '@/router/index.js'
import VueCookies from 'vue3-cookies'
import VTooltip from 'v-tooltip'
import "@shoelace-style/shoelace/dist/themes/light.css"
import "@shoelace-style/shoelace/dist/components/input/input"
import ShoelaceModelDirective from '@shoelace-style/vue-sl-model'

const store = createStore({
  state() {
    return {
      showModal: false,
      modalMode: 'login',
      plan: null
    }
  },
  mutations: {
    openModal(state, payload) {
      state.showModal = true;
      state.modalMode = payload.mode;
      state.plan = payload.plan;
    },
    closeModal(state) {
      state.showModal = false;
    }
  }
})
  
const app = createApp(App).use(router).use(ShoelaceModelDirective);
app.use(VueCookies, {
    expireTimes: "30d",
    path: "/",
    domain: "",
    secure: true,
    sameSite: "None"
});

app.config.globalProperties.$axios = axios
app.use(store).use(VTooltip)
app.mount('#app')
