<template>
    <div class="container mx-auto relative top-0 mt-24 mb-10 rounded p-5 sm:p-10 bg-gray-200">
        <h1 class="text-xl font-semibold">Tutorials</h1>
        <span>In development</span>
    </div>
</template>

<script>
    export default {
        
    }
</script>