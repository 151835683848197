<template>
  <div v-if="$store.state.showModal" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
    <!-- Modal Overlay -->
    <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div @click="closeModal" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <!-- Modal -->
      <div class="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:mx-auto sm:align-middle sm:max-w-lg w-full" style="margin-top: 15%; margin-bottom: 15%;">
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <!-- Close button -->
        <button @click="closeModal" class="absolute right-6"><i class="fa-solid fa-xmark"></i></button>

          <!-- Login/Register form -->
          <div class="sm:max-w-lg">
            <form>
              <h2 class="text-2xl mb-4">{{modalMode === 'login' ? 'Login' : (modalMode === 'recovery' ? 'Password recovery':'Register')}}</h2>

              <input v-model="loginData.email" class="border-2 border-gray-300 p-2 mb-4 rounded-md w-full" type="text" placeholder="Email"><br />
              <input v-if="modalMode != 'recovery'" v-model="loginData.password" class="border-2 border-gray-300 p-2 mb-4 rounded-md w-full" :type="!showPass?'password':'text'" placeholder="Password" v-on:keydown.enter="modalMode == 'login' ? login_reg() : false">
              <i v-if="modalMode === 'login'" @click="showPass = !showPass" :class="{'fa-eye-slash':showPass,'fa-eye':!showPass}" class="fa-regular absolute right-9 mt-3.5 cursor-pointer"></i>

              <input v-model="loginData.passwordCheck" v-if="modalMode === 'register'" class="border-2 border-gray-300 p-2 mb-4 rounded-md w-full" type="password" placeholder="Confirm Password">
              <div v-if="errorMessage" class="text-sm text-center text-red-500" :class="{'-mt-2 mb-1':modalMode === 'register','mt-0 mb-4':modalMode === 'login'}">{{errorMessage}}</div>

              <div v-if="modalMode === 'login'" @click="passwordRecovery" class="mb-2 text-right -mt-1 text-sm text-purple-800 cursor-pointer">Forgot password?</div>

              <div v-if="modalMode === 'register'" @click="loginData.agreement = !loginData.agreement" class="mb-2 cursor-pointer w-full">
                <i class="fa-regular mr-2" :class="{'fa-solid fa-square-check':loginData.agreement,'fa-regular  fa-square':!loginData.agreement}"></i>
                <label for="stay-logged-in cursor-pointer">I agree to Terms of Service</label>
              </div>
            </form>

            <!-- Login/Register button -->
            <section v-if="modalMode === 'recovery' && recoverySent" class="mt-3 sm:mt-0 sm:flex sm:flex-row-reverse w-full inline-flex justify-center py-2 border-2 border-green-600 text-green-600 text-sm rounded text-center">
              We have sent you an email
            </section>
            <div v-else class="mt-3 sm:mt-0 sm:flex sm:flex-row-reverse">
              <button @click="login_reg" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm  py-2 bg-purple-600 text-base font-medium text-white hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 sm:text-sm">
                <i v-if="loading" class="fa-solid fa-spinner animate-spin absolute mt-0.5" :class="{'mr-16':modalMode === 'login','mr-20':modalMode === 'register'}"></i> {{modalMode === 'login' ? 'Login' : (modalMode === 'recovery' ? 'Recover password': 'Register')}}
              </button>
            </div>

            <!-- Switch mode text -->
            <p class="mt-4" v-if="modalMode != 'recovery'">
              {{modalMode === 'login' ? "Don't have an account? " : "Already have an account? "}}
              <span class="text-purple-600 cursor-pointer" @click="switchMode">{{modalMode === 'login' ? 'Register' : 'Login'}}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { user_api } from '@/api';
export default {
  data() {
    return {
      loginData: {
        email:null,
        password:null,
        passwordCheck:null,
        agreement:false
      },
      loading:false,
      showPass:false,
      errorMessage:null,
      recoverySent:false
    }
  },
  computed: {
    showModal() {
      return this.$store.state.showModal;
    },
    modalMode() {
      return this.$store.state.modalMode;
    },
    plan() {
      return this.$store.state.plan;
    }
  },
  methods: {
    passwordRecovery() {
      this.$store.commit('openModal', { mode: 'register', plan: null });
    },
    login_reg() {
      if (this.modalMode == 'login') {
        this.login();
      } else if (this.modalMode == 'recovery') {
        this.recoverPass();
      } else {
        this.register();
      }
    },
    async recoverPass() {
      const recovery = await user_api(this.$axios,this.loginData,'forgot_password');
      if (recovery.success) {
        this.recoverySent = true;
        setTimeout(() => {
          this.$store.commit('closeModal');
        }, 2000);
      }
    },
    async login() {
        this.errorMessage = null;
        this.loading = true;
        try {
          const login = await user_api(this.$axios,this.loginData,'login');
          if (login.success) {
              this.successMessage = login.message
              this.$cookies.set('auth-token',login.token);
              setTimeout(() => {
                this.$store.commit('closeModal');
                if (this.$route.query.returnLink != null) {
                  this.$router.push(this.$route.query.returnLink);
                } else {
                  this.$router.push("/dashboard");
                }
              },200);
          } else {
              this.errorMessage = "Login failed. Please make sure your email and password are accurate."
              this.loading = false;
          }
        } catch (err) {
          this.errorMessage = "Error during login. Server not responding."
          console.log(err);
          this.loading = false;
        }
    },
    async register() {
      this.loading = true;
        this.errorMessage = null;
        try {
          const register = await user_api(this.$axios,this.loginData,'register');
          console.log(register.message);
          if (register.success) {
            this.$cookies.set('auth-token',register.token);
            setTimeout(() => {
              this.$store.commit('closeModal');
              if (this.plan != null) {
                this.$router.push({ 
                  path: '/dashboard/billing',
                  query: { plan: this.plan }
                });
              } else { this.$router.push('/dashboard'); }
            }, 500);
            this.closeModal();
          } else {
            this.errorMessage = register.message
          }
          this.loading = false;
        } catch (error) {
          console.error('Error during registration:', error);
          this.loading = false;
        }
    },
    closeModal() {
      this.errorMessage = null;
      this.$store.commit('closeModal');
      this.loginData = {
        email:null,
        password:null,
        passwordCheck:null,
        agreement:false
      }
    },
    switchMode() {
      this.errorMessage = null;
      if (this.modalMode === 'login') {
        this.$store.commit('openModal', { mode: 'register', plan: null });
      } else {
        this.$store.commit('openModal', { mode: 'login', plan: null });
      }
    },
  },
  mounted() {
    this.$store.commit('closeModal');
  }
};
</script>