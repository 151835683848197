<template>
    <div>
        <h2 class="mb-2 text-lg">Changing credentials</h2>
        <slot v-if="!editCred">
            <span class="text-sm">For security reasons, please enter your PostVerse password:</span><br />
            <input type="password" placeholder="Postverse password" autocomplete="off" v-model="password"  class="mt-1 block px-2.5 p-1.5 w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            <div v-if="message" class="text-sm text-center text-red-500 mt-3">{{message}}</div>
            <div class="w-full text-right mt-3">
                <button class="bg-purple-800 text-white px-4 py-2 rounded" @click="enter_section()"><i v-if="loading" class="mr-1.5 animate-spin fa-solid fa-spinner"></i> Enter</button>
            </div>
        </slot>
        <slot v-else-if="credentials">
            <label class="text-sm">Email</label><br />
            <input type="text" placeholder="Account email" autocomplete="off" v-model="credentials.email"  class="mt-1 block px-2.5 p-1.5 w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mb-2">
            <label class="text-sm">Password</label><br />
            <input :type="!showPass?'password':'text'" placeholder="Postverse password" autocomplete="off" v-model="credentials.password"  class="mt-1 block px-2.5 p-1.5 w-full rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            <i @click="showPass = !showPass" :class="{'fa-eye-slash':showPass,'fa-eye':!showPass}" class="fa-regular absolute right-9 -mt-7 cursor-pointer"></i>
            <p class="text-sm mt-2">*After updating credentials, we'll need to verify the account one more time</p>
            <div class="w-full text-right mt-5">
                <button class="bg-purple-800 text-white px-4 py-2 rounded" @click="update_cred()"><i v-if="loading" class="mr-1.5 animate-spin fa-solid fa-spinner"></i> Update</button>
            </div>
        </slot>
        <slot v-else-if="successChange">
            Credentials changed successfully
        </slot>
        <slot v-else>
            Please try again later.
        </slot>
    </div>
</template>

<script>
    import { get_account_credentials,update_credentials } from '@/api';
    export default {
        props:['modelID'],
        data() {
            return {
                password:null,
                editCred:false,
                credentials:null,
                message:null,
                loading:false,
                showPass:false,
                successChange:false
            }
        },
        methods: {
            async enter_section() {
                if (this.password.trim().length > 0) {
                    this.loading = true;
                    try {
                        const access = await get_account_credentials(this.$axios,this.$cookies.get("auth-token"),this.password,this.modelID);
                        if (access.success) {
                            this.editCred = true;
                            this.credentials = access.credentials;
                            this.loading = false;
                        } else {
                            if (access.success == false && access.message) {
                                this.message = access.message
                            } else {
                                this.message = 'Access denied, wrong credentials'
                            }
                            this.loading = false;
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        this.loading = false;
                    }
                } else {
                    this.loading = false;
                    this.message = 'Please enter the password to your account';
                }
            },
            async update_cred() {
                this.loading = true;
                try {
                    const updated = await update_credentials(this.$axios,this.$cookies.get("auth-token"),this.credentials,this.modelID);
                    if (updated.success) {
                        this.successChange = true;
                    }
                } catch (err) {
                    console.log(err);
                } finally {
                    this.loading = false;
                }
                this.credentials = null;
            }
        }
    }
</script>
