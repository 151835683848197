<template>
    <header-section :activeSection="'accounts'" />
    <div class="mx-2 flex-grow" v-if="isNewAccount || (!isNewAccount && registerData.name)">
        <div class="flex-grow container mx-auto relative top-0 mt-16 sm:mt-24 mb-10 rounded p-5 sm:p-7 sm:px-10 bg-gray-200" :class="{'sm:pt-10':!registerData.verified}">
            <div class="mt-4 sm:mt-2 ml-2">
                <nav class="font-sans text-grey-dark space-x-2">
                    <router-link class="text-purple-600 visited:text-purple-600 hover:text-purple-500" to="/dashboard/accounts">
                        Accounts
                    </router-link>
                    <span class="text-purple-400"><i class="fa-solid fa-angle-right"></i></span>
                    <span v-if="!isNewAccount" class="text-grey-dark">{{registerData.name}}</span>
                    <span v-else class="text-grey-dark">New account</span>
                </nav>
            </div>
            <div v-if="!registerData.verified && registerData.verification_report && registerData.verification_report.length>0" class="bg-red-100 border-2 border-red-300 text-black rounded p-6 mt-6">
                <h2 class="font-semibold text-lg mb-2">Verification status:</h2>
                <ul class="list-disc list-inside">
                    <li v-for="(report, index) in registerData.verification_report" :key="index">{{ report }}</li>
                </ul>
            </div>
            <div class="bg-white text-black rounded p-8 mt-6">
                <div v-if="!isNewAccount && !registerData.verified" class="w-full absolute top-0 text-center left-0 rounded-t p-1.5 text-black font-bold text-xs sm:text-sm" :class="statusColor">
                    <i class="mr-1" :class="statusIcon"></i>{{status}}
                </div>
                <section class="grid lg:grid-cols-2 sm:gap-5">
                    <div v-if="registerData.verified" class="border-2 border-white text-black rounded px-4 p-2">
                        <slot>
                            <div :style="`background-color:silver;background-image:url('${registerData.avatar}')`" class="w-16 h-16 float-left rounded-full mr-5 bg-cover bg-center"></div>
                            <div class="leading-5">
                                <div class="w-48 block truncate font-bold overflow-hidden text-xl">{{registerData.name}}</div>
                                <span v-if="registerData.username">@{{registerData.username}}</span><br />
                                <span class="font-semibold text-sm" v-if="registerData.fans_active">~ {{registerData.fans_active.toLocaleString('en-US').replace(',', ' ')}} fans<br /></span>
                                <br v-else />
                            </div>
                            <div class="clear-both"></div>
                        </slot>
                    </div>
                    <div>
                        <h1 class="text-lg font-bold mb-2" v-if="!registerData.username">{{pageTitle}}</h1>
                        <h1 class="text-sm font-semibold mt-3">Onlyfans credentials</h1>
                        <slot v-if="isNewAccount">
                            <div class="mt-3">
                                <label for="name" class="block text-sm text-gray-700">Stage name</label>
                                <input id="name" type="text" v-model="registerData.name" placeholder="Stage name" class="block px-2.5 p-1.5 w-full sm:w-96 rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                            </div>

                            <div @click="cred=!cred" class="cursor-pointer mt-3 text-sm">
                                <i class="mr-1" :class="{'fa-regular fa-square':cred,'fa-solid fa-square-check':!cred}"></i>
                                I prefer not to provide my credentials and wish to explore alternative options.
                            </div>
                            <slot v-if="cred">
                                <div class="mt-3">
                                    <label for="email" class="block text-sm text-gray-700">Email</label>
                                    <input id="email" type="email" v-model="registerData.email" placeholder="Email" class="block px-2.5 p-1.5 w-full sm:w-96 rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                                </div>

                                <div class="mt-3">
                                    <label for="password" class="block text-sm text-gray-700">Password</label>
                                    <input id="password" type="password" v-model="registerData.password" placeholder="Password" class="block px-2.5 p-1.5 w-full sm:w-96 rounded-md border border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
                                </div>
                            </slot>
                            <div v-else class="mt-2 text-sm text-red-500">
                                Please keep in mind that in this case, you need to download our app and do the verification yourself.<br />
                                <button @click="go_to_app" class="mt-5 bg-purple-800 text-white px-4 py-2 rounded">Go to app <i class="ml-1 fa-solid fa-angle-down"></i></button>
                            </div>
                            <div class="mt-3 -mb-2 text-center text-sm text-red-500">{{message}}</div>
                            <div class="w-full text-right">
                                <div class="w-full text-right mt-5"><button class="text-white px-4 py-2 rounded" :class="{'bg-purple-800':!isFormFilledOut,'bg-gray-400':isFormFilledOut}" :disabled="isFormFilledOut" @click="link_new_account()"><i class="mr-1.5" :class="{'fa-regular fa-floppy-disk':!loading,'fa-solid fa-spinner animate-spin':loading}"></i> Save</button></div>
                            </div>
                        </slot>
                        <slot v-else>
                            <div class="w-full mt-1">
                                <button @click="change_credentials_function" class="border-2 border-purple-800 text-purple-800 px-4 py-2 rounded"><i class="fa-regular fa-pen-to-square mr-1"></i> Change credentials</button>
                            </div>
                        </slot>
                    </div>
                    <div v-if="!registerData.verified" class="text-sm p-5 bg-gray-100 rounded mt-5 items-center relative h-max">
                        <section class="flex text-sm items-center">
                            <div class="pr-5">
                                <i class="fa-solid fa-shield-halved fa-2xl"></i>
                            </div>
                            <div class="text-justify flex-grow">
                                We want to reassure you that the security and privacy of your credentials are our top priorities.<br />
                                Your credentials are stored using state-of-the-art encryption methods and are only accessible by our automated system.<br /> 
                                This is necessary to ensure the seamless operation of the share for share process.<br />We are committed to providing you with a secure and reliable experience while using our platform.<br />If you have any questions or concerns, please don't hesitate to reach out to our support team.
                            </div>
                        </section>
                        <button @click="go_to_app" class="ml-12 mt-5 border-2 border-purple-800 text-purple-800 px-4 py-2 rounded"><i class="fa-solid fa-user-secret mr-1 fa-lg"></i> Explore Alternative Options</button>
                    </div>
                </section>
            </div>
            <div class="mt-3 bg-white text-black rounded p-8" :class="{'opacity-60 cursor-not-allowed':!registerData.verified}">
                <h1 class="text-lg font-bold">Upload pictures and caption for posting</h1>
                <small class="text-red-500" v-if="!registerData.verified">Account needs to be approved first</small>
                <div v-else>
                    <div class="mb-4">
                        <small>Uploading multiple images will add variety to your posts and prevent them from looking repetitive. By doing so, the system can randomize the pictures in your posts, ensuring that each post has a unique and fresh look.</small>
                    </div>
                    <transition-group name="fade" tag="div">
                        <div 
                            @click="deleteImage(index, image)" 
                            v-for="(image, index) in postData.images" 
                            :key="image.cdnUrl" 
                            class="relative float-left m-1 w-24 h-24 rounded bg-cover bg-center" 
                            :style="`background-image:url('${image.cdnUrl}/${image.naeTme}')`">
                            <div class="-right-2 absolute -top-2 cursor-pointer bg-black rounded-full w-5 h-5 flex items-center justify-center">
                            <i class="fa-solid fa-xmark text-white text-sm"></i>
                            </div>
                        </div>
                    </transition-group>
                    <div class="clear-both"></div>
                    <image-upload @emitFilesUploaded="onFilesUploaded" />
                    <div class="mt-5">
                        <label for="post-caption" class="block font-medium text-gray-700">Post caption</label>
                        <textarea id="post-caption" class="p-3 mt-1 h-36 block w-full rounded-md border border-gray-300 shadow-lg focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50" placeholder="Type something" v-model="postData.caption"></textarea>
                        <div v-if="restrictedWords" class="text-red-500 mt-2 text-center">Your caption contains restricted words</div>
                    </div>
                </div>
                <div v-if="registerData.verified" class="w-full text-right mt-5">
                    <button class="text-white px-4 py-2 rounded" :class="{'bg-green-500':successBtn,'bg-purple-800': !successBtn}" @click="add_caption_and_images()">
                        <slot v-if="successBtn"><i class="fa-solid fa-check mr-1"></i> Saved</slot>
                        <slot v-else-if="loading"><i class="fa-solid fa-spinner animate-spin mr-1"></i>Saving ...</slot>
                        <slot v-else><i class="fa-regular fa-floppy-disk mr-1.5"></i> Save</slot>
                    </button>
                </div>
            </div>
            <div class="mt-10 bg-white text-black rounded p-8" :class="{'opacity-60 cursor-not-allowed':isNewAccount}">
                <h1 class="text-lg font-bold">Delete linked account</h1>
                <small class="text-gray-700">By deleting the account, you'll also delete all the uploaded images and account history.</small><br /><br />
                <div v-if="!isNewAccount">
                    <button class="bg-purple-800 text-white px-4 py-2 rounded" @click="deleteAccount()"><i class="fa-solid fa-trash mr-1.5"></i> Delete</button>
                </div>
            </div>            
            <div v-if="showCredModal == true" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <!-- Modal Overlay -->
                <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <div @click="showCredModal = false" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true">
                    </div>
                    <div class="inline-block align-middle bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full">
                        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                            <!-- Close button -->
                            <button @click="showCredModal = false" class="absolute right-6"><i class="fa-solid fa-xmark"></i></button>
                            <change-credentials :modelID="mid" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="mx-2 flex-grow">
        <div class="animate-pulse h-screen flex-grow container mx-auto relative top-0 mt-16 sm:mt-24 mb-10 rounded p-5 sm:p-7 sm:px-10 bg-gray-200">
        </div>
    </div>
    <footer-section />
</template>

<script setup>
    import imageUpload from '@/components/imageUpload.vue'
    import headerSection from "@/components/dashboard/header.vue"
    import changeCredentials from "@/components/dashboard/changeCredentials.vue"
    import footerSection from "@/components/footer.vue"
</script>

<script>
    import { get_account_by_id } from '@/api';
    export default {
        props: {
            isNewAccount: {
                type: Boolean,
                default: false,
            },
            username: {
                type: String,
                required: false,
            },
            showError:false,
        },
        components: {headerSection,imageUpload,changeCredentials,footerSection},
        data() {
            return {
                showSuccess:false,
                cred: true,
                pageTitle: this.isNewAccount ? 'Link a new account' : `Account for @${this.$route.params.username}`,
                registerData:{name:null,email:null,password:null,verified:false},
                images: [],
                postData: {caption:null,images:[]},
                message:null,
                loading:false,
                showCredModal:false,
                mid:null,
                successBtn:false,
                restrictedWords:false,
                loadedImages: {},
            }
        },
        watch: {
            'postData.images': {
                immediate: true,
                handler(newImages) {
                    console.log("trying to change the value of this");
                    // When images are updated, clear the loadedImages array
                    this.loadedImages = [];

                    newImages.forEach((image) => {
                    // Create a new image object to preload image
                    let img = new Image();
                    img.src = `${image.cdnUrl}/-/quality/lighter/-/resize/200x/-/format/auto/`;
                    img.onload = () => {
                        // When image is loaded, push it to the loadedImages array
                        this.loadedImages.push(image);
                    };
                    });
                },
            },
        },
        computed: {
            status() {
                return this.registerData.verified ? 'Verified' : 'Waiting for verification';
            },
            statusColor() {
                return this.registerData.verified ? 'bg-green-500' : 'bg-orange-400';
            },
            statusIcon() {
                return this.registerData.verified ? 'fa-regular fa-circle-check' : 'fa-solid fa-hourglass-start';
            },
            isFormFilledOut() {
                if (this.cred) {
                    return Object.values(this.registerData).some(value => value === null || value === '');
                } else {
                    return this.registerData.name === null || this.registerData.name === '';
                }
            }
        },
        methods: {
            async deleteImage(i,file) {
                console.log(file)
                if (confirm("Are you sure you want to delete this image?")) {
                    const response = await this.$axios.post(import.meta.env.VITE_API_ENDPOINT+'/model/', {
                        action: 'delete_image',
                        file: file,
                        auth_token: this.$cookies.get('auth-token'),
                        model_id: this.$route.params.id
                    });
                    if (response.data.success) {
                        this.postData.images.splice(i, 1);
                    } else {
                        alert('Something went wrong with this action');
                    }
                }
            },
            go_to_app() {
                this.$router.push({name:'sessionApp'});
            },
            async change_credentials_function() {
                this.showCredModal = true;
            },
            async deleteAccount() {
                if (confirm('Are you sure?')) {
                    try {
                        const response = await this.$axios.post(import.meta.env.VITE_API_ENDPOINT+'/model/', {
                            action: 'delete_model',
                            auth_token: this.$cookies.get('auth-token'),
                            model_id: this.$route.params.id
                        });
                        if (response.data.success) {
                            this.$router.push("/dashboard/accounts");
                        } else {
                            alert('Something went wrong');
                        }
                    } catch (error) {
                        console.log(error);
                    }
                }
            },
            onFilesUploaded(uploadedFiles) {
                this.postData.images.push(...uploadedFiles);
            },
            updateImages(images) {
                console.log("images updated");
                this.images = images;
            },
            async add_caption_and_images() {
                this.loading = true;
                try {
                    const response = await this.$axios.post(import.meta.env.VITE_API_ENDPOINT+'/model/', {
                        action: 'add_caption_and_images',
                        auth_token: this.$cookies.get('auth-token'),
                        postData: this.postData,
                        model_id: this.$route.params.id
                    });
                    const data = response.data;
                    if (data.success == true) {
                        this.message = data.message;
                        this.successBtn = true;
                        this.restrictedWords = false;
                    } else {
                        this.postData.caption = data.string
                        this.successBtn = false;  
                        this.restrictedWords = true;
                    }
                } catch (error) {
                    this.successBtn = false;
                    console.error('Error during registration:', error);
                } finally {
                    this.loading = false;
                    setTimeout(() => {
                        this.successBtn = false;
                    }, 1500);
                }
            },
            async link_new_account() {
                this.loading = true;
                try {
                    const response = await this.$axios.post(import.meta.env.VITE_API_ENDPOINT+'/model/', {
                        action: 'link_account',
                        auth_token: this.$cookies.get('auth-token'),
                        formData: this.registerData
                    });
                    const data = response.data;
                    this.message = response.data.message
                    
                    if (data.success) {
                        this.showSuccess = true;
                        this.$router.push("/dashboard/accounts");
                    }
                    this.loading = false;
                } catch (error) {
                    console.error('Error during registration:', error);
                }
            },
        },
        async mounted() {
            window.scrollTo(0, 0);
            this.mid = this.$route.params.id;
            if (!this.isNewAccount) {
                const account = await get_account_by_id(this.$axios,this.$cookies.get("auth-token"),this.$route.params.id);
                this.registerData = account.model;
                if (account.success) {
                    this.pageTitle = `Account for ${account.model.name}`;
                    if (this.registerData.content) {
                        this.postData.caption = this.registerData.content.caption
                        this.postData.images = this.registerData.content.images
                    }
                } else {
                    this.$router.push("/dashboard/accounts");  
                }
            }
        },
    }
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>