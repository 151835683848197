<template>
  <header-section @scroll-to-section="scrollTo" />
    <div class="p-10 text-base text-gray-800 bg-white container mx-auto mt-20 mb-5">
      <h2 class="text-4xl mb-8 text-center text-purple-700 font-bold">Privacy Policy</h2>
      <div class="prose lg:prose-xl mx-auto">
        <p>At PostVerse, we respect your privacy and are committed to protecting it. This Privacy Policy explains what information we collect, how we use it, and your rights in relation to that information.</p>
        
        <h3 class="mt-5 mb-2 text-xl font-semibold">Information We Collect</h3>
        <p>We collect certain information to provide and improve our services to you. This includes:</p>
        <ul class="list-disc ml-5">
          <li>Login details to your OnlyFans account. We encrypt these details using modern encryption methods for your safety and privacy.</li>
          <li>Images used for posting on OnlyFans. We will use these images solely for the purpose of providing our service and will not share them with any third party.</li>
          <li>Invoicing details for payment purposes. This may include your name, contact details, and bank account information.</li>
        </ul>
        
        <h3 class="mt-5 mb-2 text-xl font-semibold">How We Use Your Information</h3>
        <p>We use the information we collect to provide our services to you. We do not share your personal information with third parties without your consent, unless required by law.</p>

        <h3 class="mt-5 mb-2 text-xl font-semibold">Payment Processing</h3>
        <p>We use a third-party payment gateway to process payments for our services. We also offer direct invoicing with bank transfer options. We follow legal requirements to store and protect your payment data.</p>
        
        <h3 class="mt-5 mb-2 text-xl font-semibold">Your Rights</h3>
        <p>You have the right to access the personal information we hold about you, and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us at company@msb.media</p>

        <h3 class="mt-5 mb-2 text-xl font-semibold">Changes to This Privacy Policy</h3>
        <p>We may update this Privacy Policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal, or regulatory reasons. Please re-visit this page periodically to stay aware of any changes.</p>

        <h3 class="mt-5 mb-2 text-xl font-semibold">Contact Us</h3>
        <p>If you have any questions about this Privacy Policy, please contact us at company@msb.media</p>
      </div>
    </div>

    <footer-section />
</template>

<script>
import headerSection from '@/components/header.vue';
import footerSection from '@/components/footer.vue';

export default {
  components: {
    headerSection,
    footerSection,
  },
  mounted() {
    window.scrollTo(0, 0);
  }
};
</script>