<template>
  <div class="min-h-screen flex flex-col">
    <header-section />
    <div class="flex-grow container mx-auto px-4 py-24">
      <h2 class="text-4xl mb-8 text-center text-purple-700 font-bold">Contact Us</h2>
      <div class="w-full max-w-md mx-auto">
        <p class="text-center text-white text-sm">
        If you have any questions, feel free to drop us an email at: <a href="mailto:company@msb.media" class="underline text-gray-200">company@msb.media</a>
        </p>
      </div>
    </div>
    <footer-section />
  </div>
</template>

<script>
import headerSection from '@/components/header.vue';
import footerSection from '@/components/footer.vue';

export default {
  components: {
    headerSection,
    footerSection,
  },
};
</script>

<style scoped>
</style>
