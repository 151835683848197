<template>
    <header-section @scroll-to-section="scrollTo" />
    <div class="container mx-auto relative top-0 mt-24 mb-10 rounded p-5 sm:p-10 bg-gray-200">
        <h1 class="text-3xl text-purple-500 font-bold text-center mb-14">Oops! The wind just blew your page away!</h1>
        <img :src="marilyn" class="h-128 mb-5 mx-auto" />
    </div>
    <footer-section id="footer"/>
</template>

<script setup>
    import headerSection from "@/components/header.vue"
    import footerSection from "@/components/footer.vue"
</script>

<script>
import marilyn from "@/assets/images/marilyn.svg";
export default {
    name: 'NotFound',
    data() {
        return {
            marilyn: marilyn,
        }
    },
    components() {'footerSection','headerSection'},
};
</script>