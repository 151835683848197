<template>
    <header-section @scroll-to-section="scrollTo" />
    <hero-section id="home"/>
    <about-section id="about"/>
    <pricing-section id="pricing"/>
    <faq id="faq" />
    <footer-section id="footer"/>
</template>

<script setup>
    import headerSection from "@/components/header.vue"
    import heroSection from "@/components/hero.vue"
    import aboutSection from "@/components/about.vue"
    import pricingSection from "@/components/pricing.vue"
    import footerSection from "@/components/footer.vue"
    import faq from "@/components/faq.vue"
</script>

<script>
export default {
    data() {
        return {
            bgImage: `background-image: url('https://images.pexels.com/photos/11146310/pexels-photo-11146310.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1')`
        }
    },
    components:{aboutSection,pricingSection,footerSection,headerSection,heroSection,faq},
    methods: {
        scrollTo(section) {
            const element = document.getElementById(section);
            const rect = element.getBoundingClientRect();
            const absoluteElementTop = rect.top + window.pageYOffset;
            const middle = absoluteElementTop - 50;
            window.scrollTo({ top: middle, behavior: 'smooth' });
        }
    }
}
</script>

